<template>
  <b-row>
    <b-col>
      <div >
        <loading :active.sync="isLoading" 
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"></loading>
        <div class="mb-3">
          <download-excel
              class = "btn btn-primary pull-right ml-3"
              :fetch   = "fetchData"
              :fields = "download.header"
              :before-generate = "startDownload"
              :before-finish = "finishDownload"
              :name="'Гүйлгээний түүх.xls'"
              v-if="items != undefined && items.length > 0">

                <b-spinner small type="grow" v-show="download.loading"></b-spinner> 
                <span v-show="download.loading" class="ml-1">Уншиж байна</span>
                <i class="fa fa-download" v-show="!download.loading"></i> 
                <span v-show="!download.loading" class="ml-1">Excel татах</span>
                
          </download-excel>
          <div class="pull-right">
              <b-form-select v-model="query.per_page" :options="[5, 10, 25, 100]" v-if=" items != undefined && items.length > 0">          
              </b-form-select>
            </div>
          <b-row>
            
            <b-form @submit.stop.prevent="submitSearch" class="col-md-5">
              <b-input-group >
                <b-input-group-prepend is-text><i class='fa fa-calendar'></i></b-input-group-prepend>
                <date-picker v-model="startAt" :config="{format:'YYYY-MM-DD', useCurrent: false}" placeholder="Эхлэх огноо"></date-picker>
                <date-picker v-model="endAt" :config="{format:'YYYY-MM-DD', useCurrent: false}" placeholder="Дуусах огноо"></date-picker>
                <b-input-group-append><b-button variant="primary" type="submit">Шүүх</b-button></b-input-group-append>
                <b-input-group-append><b-button variant="secondary" @click="loadData"><i class="fa fa-refresh"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form> 
          </b-row>
        </div>
    
        <div class="table-responsive" v-if="items != undefined && items.length > 0">
          <b-table 
            id="filter-table"
            stacked="md"
            @sort-changed="sortingChanged"
            :hover="true" 
            :bordered="true" 
            :small="true" 
            :fixed="false" 
            responsive="sm" 
            :items="items" 
            :fields="columns"
            no-local-sorting
            >
            
            <template v-slot:cell(index)="data">
              {{ data.index + pagination.from }}
            </template>
            <template v-slot:cell(amount_loan)="data">
                <span v-if="data.item.transactionType == 'LOAN'" class="text-danger">-{{ data.item.amount }}</span>
            </template>
            <template v-slot:cell(amount_repayment)="data">
                <span v-if="data.item.transactionType == 'PAYMENT'" class="text-primary">{{ data.item.amount }}</span>
            </template>
            <template v-slot:cell(bankName)="data">
                <span v-if="data.item.transactionType == 'LOAN'">{{ data.item.bankName }}</span>
            </template>
            <template v-slot:cell(overdueDay)="data">
                <strong class="text-danger" v-if="data.item.overdueDay">
                  {{ data.item.overdueDay }} өдөр</strong
                >
              </template>
              <template v-slot:cell(isClose)="data">
                <span v-if="data.item.isClose == true && data.item.transactionType == 'PAYMENT'">Хаах</span>
                <span v-else-if="data.item.isClose == false && data.item.transactionType == 'PAYMENT'">Эргэн төлөх</span>
              </template>
          </b-table>
                    
          <div slot="footer" class="mb-0 mt-3">
            <nav class="pull-right" v-if="pagination.total > query.per_page"><b-pagination class="mb-0" :total-rows="pagination.total" :per-page="query.per_page" v-model="query.page"/></nav>
            <p class="mb-0">{{ pagination.total }} өгөгдлөөс {{ (pagination.to)?pagination.from:0 }} - {{ pagination.to }} өгөгдөл харагдаж байна.</p>
          </div>
        </div>

        <b-alert variant="warning" v-else show class="mb-0">
          Мэдээлэл байхгүй байна
        </b-alert>

      </div>
    </b-col>
  </b-row>
</template>

<script>


import axios from 'axios';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
export default {  
  name: 'Customers.LoanTransactionHistory',
  components: {
    axios,
    datePicker
  },
  inheritAttrs: false,
  props: ['customerId'],
  data: () => {
    return {
      isLoading: false,
      filterShow: false,
      query: {
        id: 0,
        page: 1,
        per_page: 10,
        orderDirection: 'DESC',
        orderBy: 'transaction_date',
        startAt: '',
        endAt: '',
        columns:''
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0
      },
      orderDesc: false,
      items: [],
      startAt: '',
      endAt: '',
      download: {
        loading: false,
        header: {}
      },
      columns: [
            { label: '№', key: 'index', class: 'text-center'},
            { label: 'Огноо', key: 'transactionDate'},
            { label: 'Зээл', key: 'amount_loan', class: 'text-right'},
            { label: 'Эргэн төлөлт', key: 'amount_repayment', class: 'text-right'},
            { label: 'Гүйлгээний утга', key: 'description'},
            { label: 'Банк', key: 'bankName'},
            { label: 'Данс', key: 'accountNumber'},
            { label: 'Хугацаа хэтэрсэн хоног', key: 'overdueDay'},
            { label: 'Хаах/Эргэн төлөх', key: 'isClose'},
        ]
    }
  },
  created: function () {
    this.$data.query.id = this.customerId;
    this.$data.download.header = {}
    for(let i in this.$data.columns) {
      let _field = this.$data.columns[i]
      this.$data.download.header[_field.label] = _field.key
    }
    this.loadData();
  },
  watch: {
    query: {
      handler (query) {
        this.loadData();
      },
      deep: true
    },
  },
  methods: {
    loadData: function() {
  this.checkSession();
  this.isLoading = true;

  // Build query parameters dynamically from this.$data.query
  const queryParams = new URLSearchParams(this.$data.query).toString();
  
  axios.post(
    `${this.$config.NUMUR_MS_ADMIN_URL}get_loan_transaction_history?${queryParams}`,  // Append query parameters to the URL
    {
      headers: {
        ...this.$store.getters.httpHeader,
        'Content-Type': 'application/json',  // Although GET requests don't typically need this header, you can include it
      }
    }
  )
  .then(response => {
    this.isLoading = false;
    this.$data.items = response.data.data;  // Accessing the response data
    this.$data.pagination.total = response.data.total;
    this.$data.pagination.from = response.data.from;
    this.$data.pagination.to = response.data.to;
  })
  .catch(error => {
    this.isLoading = false;
    console.error("Error loading data:", error);  // Optionally log the error
  });
},
    sortingChanged(ctx) {
      let _field = ''
      for(let i in this.fields) {
        if(this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key
          break
        }
      }
      this.$data.query.orderDirection = (ctx.sortDesc)?'ASC':'DESC'
    },
    submitSearch: function() {
      this.$data.query.startAt = this.$data.startAt,
      this.$data.query.endAt = this.$data.endAt
    },
    async fetchData(){
      if(this.download.loading) {
        this.download.loading = true;
        const response = await axios.get(this.$config.NUMUR_MS_ADMIN_URL + 'get_loan_transaction_history',{
          params: {
            page: 1,
            per_page: 0,
            orderDirection: this.$data.query.orderDirection,
            orderBy: this.$data.query.orderBy,
            startAt: this.$data.query.startAt,
            endAt: this.$data.query.endAt,
            columns: this.$data.query.columns,
            id: this.$data.query.id
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        });
        if(response.data.status == 500) {
          this.showToast('Алдаа', 'Алдаа гарсан байна. Дахин оролдоно уу', 'danger')
          this.download.loading = false;
          return []
        } 
        let _result = []
        for(let i in response.data.data) {
            let _item = response.data.data[i]
            _item.index = parseInt(i)+1
            if(_item.transactionType == 'LOAN') {
                _item.amount_loan = _item.amount;
                _item.amount_repayment = '';
            } else {
                _item.amount_repayment = _item.amount;
                _item.amount_loan = ''
                _item.bankName = ''
            }
          _result.push(_item)
        }
        return _result
      }
      return []
    },
    startDownload(){
        this.download.loading = true;
    },
    finishDownload(){
        this.download.loading = false;
    }
  }
}
</script>
